import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import './page-7.css';

const PageSeven = () => (
  <>
    <SEO title="Page 7" />
    <main className="page page7">
      <StaticImage
        alt="A magazine showing the hot fashions of the season from Hoopla's House of Batwams"
        aspectRatio={6754 / 4948}
        className="page7__image"
        height={1000}
        layout="constrained"
        loading="eager"
        objectFit="contain"
        placeholder="tracedSVG"
        src="../../images/page-7.png"
      />
      <PreviousPage to="/page-6" />
      <NextPage to="/page-8" />
    </main>
  </>
);

export default PageSeven;
